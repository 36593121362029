.logo {
  margin: 10px;
  width: 140px;
  height: 81px;
}

.logo-footer {
  width: 320x;
  height: 162px;
}

.title {
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

a:link { text-decoration: none;}

a:visited { 
  text-decoration: none;
}

a:hover { 
  text-decoration: none;  
  color: #0DADEA;
  font-weight: 500;
 }

a:active { 
  text-decoration: none; 
}

.titles {
  color: #37373787;
  text-align: center;
  font-family: 'Varta', sans-serif;
  text-shadow: 1px 1px 2px rgba(98, 96, 96, 0.449);
}

.titles-terms {
  position: absolute;
  top: 37%;
  left: 40%;
  color: #1a1a1a;
  text-align: center;
  font-family: 'Varta', sans-serif;
  text-shadow: 1px 1px 2px rgba(98, 96, 96, 0.449);
}

.header {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.container {
  max-width: 98%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
  background-color: #EEF4ED;
}

.content {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Varta', sans-serif;
  color: #000000;
  text-indent: 20px;
}

.subtitle {
  font-size: large;
  font-weight: bold;
  margin-left: 10%;
  color: #0DADEA;
  font-size: x-large;
  font-family: 'Roboto Serif', serif;
}

.terms-subtitle {
  font-size: large;
  font-weight: 400;
  margin-left: 5%;
  font-family: 'Roboto Serif', serif;
}

.head-text {
  position: relative;
  margin-bottom: 2%;
}

.image-container {
  position: relative;
  box-shadow: 0 60px 80px rgba(0,0,0,0.60), 0 45px 26px rgba(0,0,0,0.14);
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: #ffffff;
  list-style: none;
  padding: 0px 10px 0px 10px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.ro-prod {
  margin-left: auto;
  margin-right: auto;
}

.prod-icon {
  width: 30px;
  height: 30px;
}

.icon-contact {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.card {
  width: 32%;
}

.contact-card .name {
  padding-top: 15%;
}


.card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card:hover {
  box-shadow: -1px 10px 29px 0px rgba(0,0,0,0.8);
}


@media (min-width: 768px) and (max-width: 1030px) {
  
  .carousel-caption-custom {
    padding-bottom: 0 !important;
   }

  .carousel-caption-custom h5 {
    font-size: medium !important;
    transform: scale(0.5);
  }

  .carousel-caption-custom p {
    font-size: small !important;
  }

  .contact-form {
    padding-top: 8%;
    padding-left: 0 !important;
    display: flex;
  }

  .contact-form-cards {
    display: flex !important;
  }

  .contact-card p {
    display: block;
    text-align: center;
    font-size: 12px !important;
  }

  .contact-card .name {
    padding-top: 30%;
  }

  .icon-contact {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .css-1jxdcj3-MuiSvgIcon-root {
    width: 20px !important;
    height: 20px !important;
  }

}


/* @media (max-width: 900px) {
  .carousel-caption-custom {
    padding-bottom: 0 !important;
   }

  .carousel-caption-custom h5 {
    font-size: xx-small;
    transform: scale(0.5);
  }

  .carousel-caption-custom p {
    font-size: xx-small;
  }

  .contact-form {
    padding-top: 8%;
    padding-left: 0 !important;
    display: flex;
  }
} */

@media (max-width: 767px) {

  .card {
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
  }

  .card-container {   
    display: block;
  }
  
  .carousel-caption-custom {
    padding-bottom: 0 !important;
   }

  .carousel-caption-custom h5 {
    font-size: small !important;
    transform: scale(0.5);
  }

  .carousel-caption-custom p {
    font-size: 7px !important;
  }


  .contact-form {
    padding-top: 8%;
    padding-left: 0 !important;
    display: flex;
  }

  .nav-link.active {
    background-color: #0dabea15 !important;
    /* border-bottom: 1px solid #002d75 !important; */
  }
  
  .nav-link:hover {
    color: #0dabea !important;
    /* border-bottom: 1px solid #002d75 !important; */
  }

  .titles-terms {
    position: absolute;
    font-size: large;
    top: 25%;
    left: 33%;
    color: #1a1a1a;
    text-align: center;
    font-family: 'Varta', sans-serif;
    text-shadow: 1px 1px 2px rgba(98, 96, 96, 0.449);
  }

}

hr.style {
  margin-top: 70px;
  margin-bottom: 30px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.footer-container {
  background-color: #A3b5c0;
}

.footer-content{
  max-width: 98%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  font-family: 'Varta', sans-serif;
}


.footer-icon {
  width: 20px;
  height: 20px;
}

.copyright {
  color: #e9e9e9;
  text-align: center;
}

.used-for {
  max-width: 90%;
  display: flex;
  align-items: center;
}


.used-for-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
}

.used-for-icon {
  padding-left: 15px;
  height: 20%;
  width: 20%;
  position: relative;
}

.grain-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.grain-container-box {
  display: inline-flex;
  margin-right: 0.625rem;
}

.grain-box {
  background-color: #3c3c3c;
  display: flex;
  align-items: center;
  height: 1.8125rem;
  line-height: 1.875rem;
  text-align: center;
  width: 1.8125rem;
}

.grain-label {
  position: absolute;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 500;
  margin: 0.5em 0.5em;
}

.high-tech-img {
  height: 100px;
  width: 100px;
  display: inline;
}

.product-description {
  display: flex;
  padding-left: 20px;
}

.contact-card {
  display: block;
  width: 350px;
}

.contact-card p {
  font-family: 'Varta', sans-serif;
  font-size: 14px;
}

.contact-card:hover {
  box-shadow: -1px 10px 29px 0px rgba(0,0,0,0.8);
}

.contact-form-cards {
  display: inline-grid;
  padding: 20px 30px 30px 10px;
}

.contact-form {
  padding-left: 2%;
  display: inline-grid;
}

.name {
  font-weight: bold;
  text-align: center;
}

.carousel-img {
  width: 100% !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


.carousel-caption-custom h5 {
  color: #59C5EC;
  font-weight: 800;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  transform: scale(0.75);
  animation: scale 2s forwards cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.carousel-caption-custom p {
  background-color: rgba(0, 0, 0, 0.489);
  color: #fff;
  font-weight: 400;
  font-size: medium;
  font-family: 'Open Sans', sans-serif;
  animation: 4s anim-lineUp ease-out;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.nav-item {
  font-family: 'Montserrat', sans-serif;
  font-size: medium;
  font-weight: 600;
  padding-right: 30px;
}

.dropdown-menu {
  background-color: #EEF4ED !important;
  /* border: none !important; */
  padding-left: 10px !important;
}

.dropdown-item:hover {
  background-color: #EEF4ED !important;
  color: #0DADEA !important;
}

.nav-link.active {
  color: #0DADEA !important;
  /* background-color: #0dabea15 !important; */
  border-bottom: 1px solid #002d75 !important;
}

.nav-link:hover {
  color: #0dabea !important;
  border-bottom: 1px solid #002d75 !important;
}

.dropdown-item.active {
  color: #0DADEA !important;
  background-color: #EEF4ED !important;
}


.ask-for-offer {
  padding: 3%;
  display: flex;
  justify-content: right;
}

.vr {
  /* color: #002d75; */
  border: 1px solid #002d75; 
  margin-left: 10px;
  margin-right: 50px;
}

.terms-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  width: 98%;
}